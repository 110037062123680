<template>
	<v-container
		fluid
	>
		<v-card-title>
			<v-spacer />
			<Button class="ml-4" @click="newAdmin = true">
				<v-icon>mdi-plus</v-icon>
			</Button>
		</v-card-title>

		<v-dialog
			v-model="newAdmin"
			width="500"
			@keydown.esc="newAdmin = false"
			persistent
		>
			<v-card>
				<v-card-title>
					New Admin
					<v-spacer />
					<Button icon text @click="newAdmin = false">
						<v-icon>mdi-close</v-icon>
					</Button>
				</v-card-title>

				<v-divider />

				<ValidationObserver
					ref="admin"
					@submit.prevent="form.id ? save() : create()"
				>
					<form autocomplete="off">
						<v-divider />
						<v-card-text class="pa-6 pt-0">
							<v-row>
								<VTextFieldValidation
									v-if="false"
									v-model="form.id"
								/>

								<v-col cols="6">
									<VTextFieldValidation
										rules="required"
										v-model="form.name"
										label="Name"
									/>
								</v-col>

								<v-col cols="6">
									<VTextFieldValidation
										rules="required"
										v-model="form.account"
										label="Account"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6">
									<VTextFieldValidation
										rules="required|email"
										v-model="form.email"
										label="E-mail"
									/>
								</v-col>

								<v-col cols="6">
									<VTextFieldValidation
										rules="required"
										v-model="form.cellphone"
										label="Cellphone"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6" v-if="!form.id">
									<VTextFieldValidation
										rules="required|min:8"
										v-model="form.password"
										type="password"
										label="Password"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6">
									<v-select
										ref="role"
										v-model="form.role"
										label="Role"
										:items="roles"
										placeholder=" "
									></v-select>
								</v-col>

							</v-row>

							<v-row>
								<v-col class="pb-0">
									<v-spacer />
									<Button
										type="submit"
										:loading="loading"
									>
										{{ form.id ? `Save` : `Create `}}
									</Button>
								</v-col>
							</v-row>

						</v-card-text>
					</form>
				</ValidationObserver>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="dialogResetPassword"
			width="500"
			@keydown.esc="dialogResetPassword = false"
			persistent
		>
			<v-card>
				<v-card-title>
					Change Password
					<v-spacer />
					<Button icon text @click="dialogResetPassword = false">
						<v-icon>mdi-close</v-icon>
					</Button>
				</v-card-title>

				<v-divider />

				<ValidationObserver
					ref="password"
					@submit.prevent="resetPassword"
				>
					<form autocomplete="off">
						<v-divider />
						<v-card-text class="pa-6 pt-0">
							<v-row>
								<v-col cols="12">
									<VTextFieldValidation
										rules="required|min:8"
										v-model="form.password"
										type="password"
										label="Password"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col class="pb-0">
									<v-spacer />
									<Button
										type="submit"
										:loading="loading"
									>
										Change password
									</Button>
								</v-col>
							</v-row>

						</v-card-text>
					</form>
				</ValidationObserver>
			</v-card>
		</v-dialog>

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="items"
							:search="search"
							:server-items-length="totalItems"
							:options.sync="options"
							item-key="id"
							:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field no-gutters clearable v-model="search" label="Search" />
									</v-col>
								</v-row>
							</template>
							<template v-slot:[`item.action`]="{ item }">
								<v-icon
									small
									class="mr-2"
									title="Reset password..."
									@click="showResetPassword(true, item)"
								>
									mdi-lock-reset
								</v-icon>
								<v-icon
									small
									class="mr-2"
									title="Edit profile..."
									@click="edit(item)"
								>
									mdi-pencil
								</v-icon>
								<v-icon
									small
									class="mr-2"
									title="Delete profile..."
									@click="deleteAdmin(item)"
								>
									mdi-delete
								</v-icon>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import VTextFieldValidation from '@/components/validation/VTextField'

export default {
	components: {
		VTextFieldValidation
	},
	data: () => ({
		loading: false,
		newAdmin: false,
		dialogResetPassword: false,

		form: {
			id: null,
			name: null,
			account: null,
			email: null,
			cellphone: null,
			role: null,
			password: null,
		},

		options: {},
		totalItems: 0,
		search: ``,

		roles: [`admin`, `manager`, `marketing`, `developer`, `ambassador`],
		newPassword: {
			user_id: null,
		},

		headers: [
			{ text: `ID`, value: `id` },
			{ text: `Name`, value: `name` },
			{ text: `Account`, value: `account` },
			{ text: `Email`, value: `email` },
			{ text: `Cellphone`, value: `cellphone` },
			{ text: 'Role', value: `role` },
			{ text: `Actions`, value: `action`, align: `center`, sortable: false },
		],
		items: [],
	}),
	methods: {
		edit(admin) {
			this.openAdmin(admin)
		},
		deleteAdmin(admin) {
			this.$root.$confirm(`Delete`, `Are you sure?`, { color: `error` })
				.then((confirm) => {
					if (confirm) {
						this.$httpInt.delete(`/v2/users/${admin.id}`)
							.then(() => this.refreshContent())
							.finally(() => this.loading = false)
					}
				})
		},
		openAdmin(admin) {
			if (admin) {
				this.form = admin
			} else {
				this.form = {
					id: null,
					name: null,
					account: null,
					email: null,
					cellphone: null,
					role: null,
					password: null,
				}
			}
			this.newAdmin = true
		},
		refreshContent () {
			this.loading = true
			this.$httpInt.get(`/v2/users/`, { params: this.$paginationParams(this) })
				.then(res => {
					this.items = res.data.result
					this.totalItems = res.data.pagination.total
				})
				.finally(() => this.loading = false)
		},
		create() {
			this.$refs.admin.validate().then(valid => {
				if (!valid) return
				this.loading = true

				this.$httpInt.post(`/v2/users/`, this.form)
					.then(() => {
						this.newAdmin = false
						this.refreshContent()
					})
					.finally(() => this.loading = false)
			})
		},
		showResetPassword(show, user) {
			if (show) {
				this.newPassword.user_id = user.id
			} else {
				this.newPassword.user_id = null
			}
			this.dialogResetPassword = show
		},
		resetPassword() {
			this.$refs.password.validate().then(valid => {
				if (!valid) return
				this.loading = true

				this.$httpInt.patch(`/v2/users/${this.newPassword.user_id}`, {
					password: this.form.password
				})
					.then(() => {
						this.newAdmin = false
						this.refreshContent()
					})
					.finally(() => {
						this.setLoading(false)
						this.dialogResetPassword = false
					})
			})
		},
		setLoading(loading) {
			this.loading = loading
		},
		save() {
			this.$refs.admin.validate().then(valid => {
				if (!valid) return
				this.loading = true

				this.$httpInt.patch(`/v2/users/${this.form.id}`, this.form)
					.then(() => {
						this.newAdmin = false
						this.refreshContent()
					})
					.finally(() => this.loading = false)
			})
		},
	},
	mounted () {
		this.refreshContent()
	},
	name: `SystemAdmins`,
	watch: {
		options: {
			handler () {
				this.refreshContent()
			},
			deep: true,
		},
		search: {
			handler () {
				this.$debounce(() => this.refreshContent(), 300)
			},
			deep: true,
		},
	},
}
</script>
